
export default {
  data() {
    return {
      types: []
    };
  },
  async fetch() {
    this.types = await this.$apiClaimTypes.all();
  }
};
